import { TimeSlot } from 'common/types/TimeSlot';
import {
  formatDateString,
  formatStartEndHoursString,
} from 'utils/helpers/formatDateTimes';
import {
  JobAvailableTimesGroup,
  JobAvailableTimeTile,
  JobAvailableDate,
  JobAvailableTime,
} from '../JobDetail.styles';

type Prop = {
  timeZone: string;
  availableTimeSlots: TimeSlot[];
  acceptedTimeSlot?: TimeSlot;
};

const JobAvailableWorkingTimeSlot = ({
  timeZone,
  availableTimeSlots,
  acceptedTimeSlot,
}: Prop) => {
  if (!availableTimeSlots || availableTimeSlots?.length === 0) return <></>;
  return (
    <JobAvailableTimesGroup>
      {availableTimeSlots?.map((avt, i) => {
        const highlight =
          avt.startDate === acceptedTimeSlot?.startDate &&
          avt.endDate === acceptedTimeSlot?.endDate;

        return (
          <JobAvailableTimeTile key={i} highlight={highlight}>
            <JobAvailableDate variant="bodyLarge" highlight={highlight}>
              {formatDateString(avt.startDate, timeZone)}
            </JobAvailableDate>
            <JobAvailableTime variant="bodyLarge" highlight={highlight}>
              {formatStartEndHoursString(avt.startDate, avt.endDate, timeZone)}
            </JobAvailableTime>
          </JobAvailableTimeTile>
        );
      })}
    </JobAvailableTimesGroup>
  );
};

export default JobAvailableWorkingTimeSlot;
