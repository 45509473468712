import type { SVGAttributes } from 'react';

const CalendarAddOnIcon = (props: SVGAttributes<SVGElement>) => (
  <svg
    width="15"
    height="17"
    viewBox="0 0 15 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.16447 14.1H1.57895C1.14474 14.1 0.772895 13.9435 0.463421 13.6304C0.154474 13.3168 0 12.94 0 12.5V3.7C0 3.26 0.154474 2.88347 0.463421 2.5704C0.772895 2.2568 1.14474 2.1 1.57895 2.1H2.36842V0.5H3.94737V2.1H8.68421V0.5H10.2632V2.1H11.0526C11.4868 2.1 11.8587 2.2568 12.1682 2.5704C12.4771 2.88347 12.6316 3.26 12.6316 3.7V8.56C12.5 8.53333 12.3718 8.51653 12.2471 8.5096C12.1218 8.5032 11.9868 8.5 11.8421 8.5C11.6974 8.5 11.5626 8.5032 11.4379 8.5096C11.3126 8.51653 11.1842 8.53333 11.0526 8.56V6.1H1.57895V12.5H7.16447C7.13816 12.6333 7.12158 12.7635 7.11474 12.8904C7.10842 13.0168 7.10526 13.1533 7.10526 13.3C7.10526 13.4467 7.10842 13.5832 7.11474 13.7096C7.12158 13.8365 7.13816 13.9667 7.16447 14.1ZM11.0526 16.5V14.1H8.68421V12.5H11.0526V10.1H12.6316V12.5H15V14.1H12.6316V16.5H11.0526Z"
      fill="currentColor"
    />
  </svg>
);

export default CalendarAddOnIcon;
