import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { WorkOrder } from 'common/types/WorkOrder';
import EditNoteIcon from 'styleguide/src/components/icons/EditNoteIcon';
import DuplicateIcon from 'styleguide/src/components/icons/DuplicateIcon';
import DeleteIcon from 'styleguide/src/components/icons/DeleteIcon';
import HintIcon from 'styleguide/src/components/icons/HintIcon';
import ProjectIcon from 'styleguide/src/components/icons/ProjectIcon';
import Loader from 'styleguide/src/components/Loader';
import ChatIcon from 'styleguide/src/components/icons/ChatIcon';
import CalendarAddOnIcon from 'styleguide/src/components/icons/CalendarAddOnIcon';
import Profile from 'styleguide/src/components/Profile';
import Link from 'styleguide/src/components/Link';
import Error from 'styleguide/src/components/Error';
import { useRemoveWorkOrderMutation } from 'services/endpoints/workOrder';
import { getErrorMsg } from 'common/utils/error';
import JobNotifyWithAction from './JobAlertWithAction';
import {
  JobDetailSection,
  JobIdentifier,
  JobTitle,
  JobDescription,
  JobItemHead,
  JobItem,
  JobItemParentProjectContainer,
  JobItemParentProject,
  JobItemParentProjectTitle,
  JobItemParentProjectId,
  IconWrapper,
  JobSalaryType,
  JobAssigneeContainer,
  JobItemParentProjectWrapper,
} from '../JobDetail.styles';
import { ErrorWrapper } from 'components/userManagement/UserManagement.styles';
import { generateGoogleCalendarLinkForWorkOrder } from 'utils/helpers/generateGoogleCalendarLink';
import dynamic from 'next/dynamic';
import { setJobTemplate } from 'common/store/slices/workOrderSlice';

// Hooks
import { useAppDispatch } from 'hooks/store';

// Utils
import convertWorkOrder from 'utils/helpers/convertWorkOrder';
import { BASE_URL } from 'common/constants';

const TextEditor = dynamic(
  () => import('styleguide/src/components/TextEditor'),
  {
    ssr: false,
  },
);

type JobSummaryDetailProp = {
  workOrder: WorkOrder;
  onBackToUserJob?: () => void;
  onProjectClick?: () => void;
  refetch?: (workOrder: WorkOrder) => void;
};

const JobSummaryDetail = ({
  workOrder,
  onBackToUserJob,
  onProjectClick,
  refetch,
}: JobSummaryDetailProp) => {
  const router = useRouter();

  const dispatch = useAppDispatch();

  const [deleteWorkOrder, { isLoading: loading, error: _error }] =
    useRemoveWorkOrderMutation();

  const error = getErrorMsg(_error);

  const onAddToCalendar = useCallback(async () => {
    const calendarUrl = await generateGoogleCalendarLinkForWorkOrder(workOrder);
    window.open(calendarUrl);
  }, [workOrder]);

  const onOpenSalesforce = useCallback(async () => {
    window.open(
      `${BASE_URL}/admin/salesforce/deeplink/${workOrder.salesforceId}`,
    );
  }, [workOrder.salesforceId]);

  const onDeleteWorkOrder = useCallback(() => {
    if (workOrder?.id && workOrder?.title) {
      const deleteDescription = `Are you sure to delete "${workOrder.title}"?`;
      const isDeleteWorkOrder = confirm(deleteDescription);
      if (isDeleteWorkOrder) {
        deleteWorkOrder(workOrder.id);
        if (onBackToUserJob) {
          onBackToUserJob();
        } else {
          router.push('/jobs-and-projects');
        }
      }
    }
  }, [deleteWorkOrder, router, onBackToUserJob, workOrder.id, workOrder.title]);

  const onDuplicateJob = useCallback(() => {
    if (workOrder) {
      dispatch(setJobTemplate(convertWorkOrder(workOrder)));
      router.push('/jobs-and-projects/jobs/create');
    }
  }, [dispatch, workOrder, router]);

  const getWorkOrderEditPath = useCallback((workOrder: WorkOrder) => {
    if (workOrder.type === 'PROJECT') {
      return `/jobs-and-projects/projects/${workOrder?.id}/edit`;
    }
    return `/jobs-and-projects/jobs/${workOrder?.id}/edit`;
  }, []);

  if (!workOrder) return <></>;

  if (loading) {
    return <Loader />;
  }

  const {
    id,
    assignee,
    market,
    location,
    title,
    description,
    projectId,
    salesforceId,
    project,
    paymentDetails,
    tenantInformation,
  } = workOrder;

  const { cityName, stateCode } = market;
  const marketName = cityName + ', ' + stateCode;
  const { building, street, postalCode } = location;
  const locationLabel = `${building} ${street}, ${market.cityName} ${market.stateCode}, ${postalCode}`;

  const actionButtons = [
    {
      icon: <CalendarAddOnIcon />,
      label: 'Add to Google Calendar',
      onSelect: onAddToCalendar,
    },
    salesforceId && {
      icon: <HintIcon />,
      label: 'Open in Salesforce',
      onSelect: onOpenSalesforce,
    },
    {
      icon: <EditNoteIcon />,
      label: 'Edit this Job',
      link: getWorkOrderEditPath(workOrder),
    },
    {
      icon: <DuplicateIcon />,
      label: 'Duplicate',
      onSelect: onDuplicateJob,
    },
    {
      icon: <DeleteIcon />,
      label: 'Delete',
      onSelect: onDeleteWorkOrder,
    },
  ].filter((item) => Boolean(item));

  return (
    <>
      {error && (
        <ErrorWrapper>
          <Error errorMessage={error} />
        </ErrorWrapper>
      )}
      <JobDetailSection>
        <JobIdentifier variant="bodySmall">Job-{id}</JobIdentifier>
        {title && <JobTitle variant="heading3">{title}</JobTitle>}
        {description && (
          <JobDescription>
            <TextEditor content={description} readOnly />
          </JobDescription>
        )}
        <JobNotifyWithAction
          workOrder={workOrder}
          items={actionButtons}
          refetch={refetch}
        />
        <JobItemHead variant="ctaSmall">Job Market</JobItemHead>
        <JobItem variant="bodyStandard">{marketName}</JobItem>
        <JobItemHead variant="ctaSmall">Job location</JobItemHead>
        <JobItem variant="bodyStandard">{locationLabel}</JobItem>
        {paymentDetails?.amount !== undefined && (
          <>
            <JobItemHead variant="ctaSmall">Salary</JobItemHead>
            <JobItem variant="bodyStandard">
              {paymentDetails?.type === 'N/A'
                ? 'N/A'
                : `$${paymentDetails.amount} `}
              {paymentDetails?.type !== 'N/A' && (
                <JobSalaryType>
                  {paymentDetails?.type && `/ ${paymentDetails.type}`}
                </JobSalaryType>
              )}
            </JobItem>
          </>
        )}
        <JobItemHead variant="ctaSmall">Assignee</JobItemHead>
        <JobItem variant="bodyStandard">
          {assignee ? (
            <JobAssigneeContainer>
              <Profile
                user={assignee}
                options={{
                  email: true,
                  fullName: true,
                  baseUrl: '/user-management',
                }}
              />
              <Link href={`/chat/job/${workOrder.id}-${assignee.id}`}>
                <ChatIcon
                  color="rgba(19, 32, 19, 0.8)"
                  style={{ marginLeft: 45, cursor: 'pointer' }}
                />
              </Link>
            </JobAssigneeContainer>
          ) : (
            'Unclaimed'
          )}
        </JobItem>
        {tenantInformation && (
          <>
            <JobItemHead variant="ctaSmall">Tenant Information</JobItemHead>
            <JobItem variant="bodyStandard">{tenantInformation}</JobItem>
          </>
        )}

        {projectId && project && (
          <>
            <JobItemHead variant="ctaSmall">Project</JobItemHead>
            <Link href={`/jobs-and-projects/${projectId}`}>
              <JobItemParentProjectWrapper onClick={onProjectClick}>
                <JobItem>
                  <JobItemParentProjectContainer>
                    <IconWrapper>
                      <ProjectIcon />
                    </IconWrapper>
                    <JobItemParentProject>
                      <JobItemParentProjectId variant="ctaSmall">
                        {project.type}-{project.id}
                      </JobItemParentProjectId>
                      <JobItemParentProjectTitle variant="bodyStandard">
                        {project?.title}
                      </JobItemParentProjectTitle>
                    </JobItemParentProject>
                  </JobItemParentProjectContainer>
                </JobItem>
              </JobItemParentProjectWrapper>
            </Link>
          </>
        )}
      </JobDetailSection>
    </>
  );
};

export default JobSummaryDetail;
