import { ReactElement } from 'react';
import useParseHTML from '../../hooks/useParseHTML';

import { IconWrapper, TagWrapper, TextWrapper } from './Tag.styles';

export type TagProps = {
  variant?: 'primary' | 'secondary';
  className?: string;
  text: string | ReactElement;
  icon?: string | ReactElement;
  iconPosition?: 'left' | 'right';
};

const Tag = ({
  text,
  icon,
  variant = 'primary',
  iconPosition = 'left',
  className,
  ...props
}: TagProps) => {
  const { parseContent } = useParseHTML();
  const Icon = () => (
    <IconWrapper className="Wreno_Tag--icon-wrapper">
      {typeof icon === 'string' ? parseContent(icon) : icon}
    </IconWrapper>
  );

  return (
    <TagWrapper className={className} $variant={variant}>
      {icon && iconPosition === 'left' ? <Icon /> : null}
      <TextWrapper {...props}>{text}</TextWrapper>
      {icon && iconPosition === 'right' ? <Icon /> : null}
    </TagWrapper>
  );
};

export default Tag;
