import styled from 'styled-components';
import Button from 'styleguide/src/components/Button';
import { BodyStandard } from 'styleguide/src/components/Typography/Typography.styles';

interface StatusCellIndicatorPops {
  color?: string;
}

export const StatusCellIndicator = styled.div<StatusCellIndicatorPops>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: ${({ color }) => color || '#e5e9eb'};
`;

export const StatusCellTitle = styled.div`
  color: ${(props) => props.theme.colors.black};
  ${BodyStandard};
`;

export const StatusCellTitleAndIndicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StatusCellDetailsButton = styled(Button)`
  background-color: ${(props) => props.theme.colors.white};
  height: 32px;
  margin-right: 10px;
  transition: none;

  :not(:focus-visible) {
    opacity: 0;
    position: absolute;
  }

  &:focus-visible ~ ${StatusCellTitleAndIndicator} {
    display: none;
  }

  &:focus-visible ~ a {
    opacity: 1;
    position: static;
  }
`;

export const StatusCellEditButton = styled(Button)`
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.machineGreen600};
  min-width: initial;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  transition: none;
  :not(:focus-visible) {
    opacity: 0;
    position: absolute;
  }

  &:focus-visible ~ ${StatusCellTitleAndIndicator} {
    display: none;
  }
`;
export const EditButton = styled(Button)`
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.machineGreen600};
  min-width: initial;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin-top: 8px;
`;
