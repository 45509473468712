import type { SVGAttributes } from 'react';

const CircleAlertIcon = (props: SVGAttributes<SVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="mask0_2632_5930"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_2632_5930)">
      <path
        d="M12 18.2C12.3667 18.2 12.6833 18.0707 12.95 17.812C13.2167 17.554 13.3583 17.2333 13.375 16.85H10.625C10.6417 17.2333 10.7833 17.554 11.05 17.812C11.3167 18.0707 11.6333 18.2 12 18.2ZM8.2 15.575H15.825C16.0583 15.575 16.25 15.4957 16.4 15.337C16.55 15.179 16.625 14.9917 16.625 14.775C16.625 14.5417 16.546 14.346 16.388 14.188C16.2293 14.0293 16.0333 13.95 15.8 13.95H15.625V11.3C15.625 10.3667 15.379 9.51667 14.887 8.75C14.3957 7.98333 13.7167 7.5 12.85 7.3V6.625C12.85 6.39167 12.771 6.19167 12.613 6.025C12.4543 5.85833 12.25 5.775 12 5.775C11.75 5.775 11.5457 5.85833 11.387 6.025C11.229 6.19167 11.15 6.39167 11.15 6.625V7.3C10.2833 7.5 9.60433 7.979 9.113 8.737C8.621 9.49567 8.375 10.3417 8.375 11.275V13.95H8.175C7.95833 13.95 7.775 14.0293 7.625 14.188C7.475 14.346 7.4 14.5417 7.4 14.775C7.4 14.9917 7.47933 15.179 7.638 15.337C7.796 15.4957 7.98333 15.575 8.2 15.575ZM12 21.5C10.6833 21.5 9.446 21.25 8.288 20.75C7.12933 20.25 6.125 19.575 5.275 18.725C4.425 17.875 3.75 16.8707 3.25 15.712C2.75 14.554 2.5 13.3167 2.5 12C2.5 10.6833 2.75 9.44567 3.25 8.287C3.75 7.129 4.425 6.125 5.275 5.275C6.125 4.425 7.12933 3.75 8.288 3.25C9.446 2.75 10.6833 2.5 12 2.5C13.3167 2.5 14.5543 2.75 15.713 3.25C16.871 3.75 17.875 4.425 18.725 5.275C19.575 6.125 20.25 7.129 20.75 8.287C21.25 9.44567 21.5 10.6833 21.5 12C21.5 13.3167 21.25 14.554 20.75 15.712C20.25 16.8707 19.575 17.875 18.725 18.725C17.875 19.575 16.871 20.25 15.713 20.75C14.5543 21.25 13.3167 21.5 12 21.5ZM12 20C14.2167 20 16.1043 19.221 17.663 17.663C19.221 16.1043 20 14.2167 20 12C20 9.78333 19.221 7.89567 17.663 6.337C16.1043 4.779 14.2167 4 12 4C9.78333 4 7.896 4.779 6.338 6.337C4.77933 7.89567 4 9.78333 4 12C4 14.2167 4.77933 16.1043 6.338 17.663C7.896 19.221 9.78333 20 12 20ZM10 13.95V10.875C10 10.325 10.196 9.854 10.588 9.462C10.9793 9.07067 11.45 8.875 12 8.875C12.55 8.875 13.021 9.07067 13.413 9.462C13.8043 9.854 14 10.325 14 10.875V13.95H10Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default CircleAlertIcon;
