import styled from 'styled-components';
import { BodySmall } from '../Typography/Typography.styles';

export const IconWrapper = styled.div`
  height: 20px;
  width: 20px;

  & svg {
    height: inherit;
    width: inherit;
  }
`;

export const TagWrapper = styled.div<{
  $variant: 'primary' | 'secondary' | 'tertiary';
}>`
  ${BodySmall};
  background: ${({ theme, $variant }) =>
    $variant === 'primary'
      ? theme.colors.gray200
      : $variant === 'secondary'
        ? theme.colors.gray400
        : 'none'};
  font-weight: 500;
  border-radius: 8px;
  padding: 8px;
  color: ${({ theme }) => theme.colors.black};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  gap: 8px;
  box-sizing: border-box;
`;

export const TextWrapper = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
