import JobWorkFormModal from 'components/jobs/detail/components/JobWorkForms/JobFormModal';
import { useState, KeyboardEvent } from 'react';
import FormAbbreviation from 'styleguide/src/components/FormAbbreviation';
import { FormInstance, FormStatus } from 'common/types/Form';
import { WorkOrder } from 'common/types/WorkOrder';
import { FormsCellStyled } from './FormsCell.styles';
import { keyHandler } from 'styleguide/src/utils/accessibilityUtils';

const FormsCell = ({ formInstances, id, title }: Partial<WorkOrder>) => {
  const [openFormModal, setOpenFormModal] = useState(false);
  const [selectedForm, setSelectedForm] = useState<FormInstance | undefined>(
    undefined,
  );

  if (!formInstances || formInstances?.length === 0) return <></>;

  const onFormClick = (form: FormInstance) => {
    setOpenFormModal(true);
    setSelectedForm(form);
  };

  const onCloseModal = () => {
    setOpenFormModal(false);
    setSelectedForm(undefined);
  };

  return (
    <FormsCellStyled>
      {formInstances?.length > 0 &&
        formInstances.map((instance) => {
          return (
            <FormAbbreviation
              value={instance?.form?.abbreviation}
              key={instance.id}
              onClick={(e) => {
                e.stopPropagation();
                onFormClick(instance);
              }}
              fill={instance?.status === FormStatus.SUBMITTED}
              tabIndex={0}
              onKeyDown={(e: KeyboardEvent) => {
                e.stopPropagation();
                keyHandler({
                  keyEvent: e as KeyboardEvent,
                  eventHandler: () => onFormClick(instance),
                });
              }}
            />
          );
        })}
      {openFormModal && (
        <JobWorkFormModal
          formInstance={selectedForm}
          jobId={id}
          jobTitle={title}
          open={openFormModal}
          onClose={onCloseModal}
        />
      )}
    </FormsCellStyled>
  );
};

export default FormsCell;
