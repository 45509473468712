import styled from 'styled-components';

export const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StatusLabelText = styled.div`
  margin-left: 6px;
`;

export const StatusLabelIcon = styled.div`
  height: 10px;
  width: 10px;
  background: ${({ color, theme }) => color || theme.colors.cobaltBlue100};
  border-radius: 50%;
  margin: 5px;
  flex-shrink: 0;
`;

export const Span = styled.span`
  margin-left: 6px;
`;
