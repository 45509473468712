const removeNonNumbersFromString = (value: string): number | string => {
  if (value === undefined) return '';
  return value.replace(/[^0-9.]/g, '');
};

interface FormatSetting {
  countryCode: boolean;
  removeCountryCode: boolean;
  removeCountryCodeFormat: boolean;
}

const formatPhoneNumber = (
  value?: string,
  setting?: Partial<FormatSetting>,
): string | undefined => {
  if (!value) return undefined;

  const matcher = /^(1|)?(\d{3})(\d{3})(\d{4})$/;
  const { countryCode, removeCountryCode, removeCountryCodeFormat } =
    setting || {};

  if (removeCountryCode) {
    const removedCountryCodeStr = value.split('+1')[1];
    if (removeCountryCodeFormat) {
      const match = removedCountryCodeStr.match(matcher);
      if (match) {
        return [match[2], '-', match[3], '-', match[4]].join('');
      }
      return undefined;
    }
    return removedCountryCodeStr;
  }

  const cleaned = `${removeNonNumbersFromString(value)}`;
  const match = cleaned.match(matcher);

  if (match) {
    const intlCode = match[1] ? (countryCode ? '+1 ' : '+1 ') : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }

  return undefined;
};

export { removeNonNumbersFromString, formatPhoneNumber };
