import type { SkillRequest } from 'common/types/SkillTag';
import type { WorkOrder, WorkOrderWithIdRequest } from 'common/types/WorkOrder';

/**
 * Convert work order object to the server request format.
 */
const convertWorkOrder = (data: WorkOrder): WorkOrderWithIdRequest => ({
  id: data?.id,
  projectId: data.projectId,
  title: data.title,
  description: data.description,
  type: data.type,
  status: data.status,
  paymentDetails: data.paymentDetails,
  location: {
    ...data.location,
    address: `${data.location.building} ${data.location.street}, ${data.location.postalCode}`,
  },
  marketId: data.market.id,
  formIds: data.formInstances.map((form) => form.formId),
  attachments: data.attachments,
  skillRequestIds: data.skillRequests?.map(
    (request: SkillRequest) => request.id,
  ),
  availableTimeSlots: data.availableTimeSlots.map((time) => ({
    id: time.id,
    startDate: time.startDate,
    endDate: time.endDate,
  })),
  acceptedTimeSlot: data.acceptedTimeSlot,
  subJobs: data.subJobs,
  assigneeId: data.assignee?.id,
  estimatedWorkHours: data.estimatedWorkHours,
  tasks: data?.tasks,
  tenantInformation: data?.tenantInformation,
});

export default convertWorkOrder;
