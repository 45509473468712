import ChatIcon from 'styleguide/src/components/icons/ChatIcon';
import Profile from 'styleguide/src/components/Profile';
import { Account } from 'common/types/Account';
import Link from 'styleguide/src/components/Link';

import {
  JobDetailSection,
  JobItemHead,
  JobAssigneeContainer,
} from '../JobDetail.styles';

type JobCoordinatorDetailProp = {
  coordinator: Partial<Account>;
};

const JobCoordinatorDetail = ({ coordinator }: JobCoordinatorDetailProp) => {
  if (!coordinator) return <></>;

  return (
    <JobDetailSection>
      <JobItemHead variant="ctaSmall">Assigned Coordinator</JobItemHead>
      <JobAssigneeContainer>
        <Profile
          user={coordinator}
          options={{ email: true, fullName: true, baseUrl: '/user-management' }}
        />
        <Link href={`/chat/direct/${coordinator.id}`}>
          <ChatIcon
            color="rgba(19, 32, 19, 0.8)"
            style={{ marginLeft: 45, cursor: 'pointer' }}
          />
        </Link>
      </JobAssigneeContainer>
    </JobDetailSection>
  );
};

export default JobCoordinatorDetail;
