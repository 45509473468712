import { compareAsc } from 'date-fns';
import { WorkOrder } from 'common/types/WorkOrder';
import { formatPhoneNumber } from 'common/utils/helpers/removeNonNumbersFromString';

export const generateGoogleCalendarLinkForWorkOrder = async (
  workOrder: WorkOrder,
): Promise<string> => {
  const {
    id,
    assignee,
    availableTimeSlots,
    market,
    location,
    endTime,
    skillRequests,
    startTime,
  } = workOrder;

  const { building, street, postalCode } = location;
  const locationLabel = `${building} ${street}, ${market.cityName} ${market.stateCode}, ${postalCode}`;
  const workOrderAddress = `${building} ${street}, ${market.cityName} ${market.stateCode}, ${postalCode}`;

  const firstSkillRequest =
    skillRequests &&
    skillRequests?.[0] &&
    `${skillRequests[0]?.categoryTitle} -> ${skillRequests[0]?.itemTitle} -> ${skillRequests[0]?.title}`;

  const availableTimeSlot = (): [string, string] => {
    const today = new Date();
    const sortedTimeSlots = availableTimeSlots
      ?.filter((t) => compareAsc(new Date(t.startDate), today) >= 0)
      .sort((a, b) => compareAsc(new Date(a.startDate), new Date(b.startDate)));

    if (sortedTimeSlots.length === 0) {
      return [new Date().toISOString(), new Date().toISOString()];
    }

    return [
      new Date(sortedTimeSlots[0].startDate).toISOString(),
      new Date(sortedTimeSlots[0].endDate).toISOString(),
    ];
  };

  const title = workOrderAddress + ' - ' + firstSkillRequest;
  const worker = assignee
    ? `${assignee.firstName} ${assignee.lastName} - ${formatPhoneNumber(
        assignee.phoneNumber,
        { removeCountryCode: true, removeCountryCodeFormat: true },
      )}`
    : '';
  const domain = window.location.origin;
  const details = `<h4>Details:</h4><div>${domain}/jobs-and-projects/${id}</div><br/><div>**This is an automatically generated event, however, any edits will not be synced with Homebase.**</div>`;
  const description = assignee
    ? `<h4>Worker:</h4><div>${worker}</div>` + details
    : details;

  const [startAvaialbleTime, endAvailableTime] = availableTimeSlot();
  const start = startTime
    ? new Date(startTime).toISOString()
    : startAvaialbleTime;
  const end = endTime ? new Date(endTime).toISOString() : endAvailableTime;
  const { google } = await import('calendar-link');
  const event = {
    title,
    description,
    start,
    end,
    location: locationLabel,
    url: window.location.href,
  };

  return await google(event);
};
