import { WorkOrder } from 'common/types/WorkOrder';
import {
  JobAttachmentsDetail,
  JobSummaryDetail,
  JobCoordinatorDetail,
  JobWorkingDetail,
  JobTaskList,
  JobWorkForm,
} from 'components/jobs/detail';
import {
  CloseJobDetails,
  JobDetailContainer,
} from 'components/jobs/detail/JobDetail.styles';
import Loader from 'styleguide/src/components/Loader';
import JobTagDetail from './components/JobTagDetail';
import CloseIcon from 'styleguide/src/components/icons/CloseIcon';

type Prop = {
  workOrder: WorkOrder;
  onBackToUserJob?: () => void;
  refetch?: (workOrder: WorkOrder) => void;
  onClose?: () => void;
};

const JobDetail = ({ workOrder, onBackToUserJob, refetch, onClose }: Prop) => {
  const {
    id,
    tasks,
    attachments,
    formInstances,
    creator,
    services,
    skillRequests,
  } = workOrder || {};

  if (!id) {
    onBackToUserJob && onBackToUserJob();
    return <Loader />;
  }

  return (
    <JobDetailContainer>
      {onClose && (
        <CloseJobDetails onClick={onClose}>
          <CloseIcon />
        </CloseJobDetails>
      )}
      <JobSummaryDetail
        workOrder={workOrder}
        onBackToUserJob={onBackToUserJob}
        onProjectClick={onClose}
        refetch={refetch}
      />
      <JobWorkingDetail workOrder={workOrder} />
      <JobWorkForm
        formInstances={formInstances}
        jobId={workOrder?.id}
        jobTitle={workOrder?.title}
      />
      <JobTaskList tasks={tasks} />
      <JobAttachmentsDetail attachments={attachments} />
      <JobTagDetail services={services} skillRequests={skillRequests} />
      <JobCoordinatorDetail coordinator={creator} />
    </JobDetailContainer>
  );
};

export default JobDetail;
