import type { SVGAttributes } from 'react';

const EditNoteIcon = (props: SVGAttributes<SVGElement>) => (
  <svg
    width="18"
    height="15"
    viewBox="0 0 18 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.5 14.5V13.075L14.65 7.925L16.075 9.35L10.925 14.5H9.5ZM0.5 9.5V8.5H7.5V9.5H0.5ZM16.775 8.65L15.35 7.225L16.075 6.5C16.175 6.38333 16.2917 6.325 16.425 6.325C16.5583 6.325 16.675 6.38333 16.775 6.5L17.5 7.225C17.6167 7.325 17.675 7.44167 17.675 7.575C17.675 7.70833 17.6167 7.825 17.5 7.925L16.775 8.65ZM0.5 5.5V4.5H11.5V5.5H0.5ZM0.5 1.5V0.5H11.5V1.5H0.5Z"
      fill="currentColor"
    />
  </svg>
);

export default EditNoteIcon;
