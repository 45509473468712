import type { SVGAttributes } from 'react';

const DeleteIcon = (props: SVGAttributes<SVGElement>) => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.6663 9.375V19.7917H8.33301V9.375H16.6663ZM15.1038 3.125H9.89551L8.85384 4.16667H5.20801V6.25H19.7913V4.16667H16.1455L15.1038 3.125ZM18.7497 7.29167H6.24967V19.7917C6.24967 20.9375 7.18717 21.875 8.33301 21.875H16.6663C17.8122 21.875 18.7497 20.9375 18.7497 19.7917V7.29167Z"
      fill="currentColor"
    />
  </svg>
);

export default DeleteIcon;
