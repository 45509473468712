import { CSSProperties, ReactElement } from 'react';
import theme from '../../themes/theme';
import {
  StatusWrapper,
  StatusLabelText,
  StatusLabelIcon,
  Span,
} from './StatusLabel.styles';

type StatusLabelProps = {
  color?: string;
  text?: string | ReactElement;
  other?: string;
  textStyle?: CSSProperties;
  otherStyle?: CSSProperties;
};

const defaultTextStyle = {
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '20px',
  color: '#132013',
};

const StatusLabel = ({
  color,
  text,
  other,
  textStyle,
  otherStyle,
  ...iconProps
}: StatusLabelProps) => {
  return (
    <StatusWrapper data-testid="status-label">
      <StatusLabelIcon color={color} {...iconProps} />
      {text && (
        <StatusLabelText style={{ ...defaultTextStyle, ...textStyle }}>
          {text}{' '}
          {other && (
            <Span
              style={{
                ...defaultTextStyle,
                color: theme.colors.machineGreen600,
                ...otherStyle,
              }}
            >
              {other}
            </Span>
          )}
        </StatusLabelText>
      )}
    </StatusWrapper>
  );
};

export default StatusLabel;
