import { intervalToDuration } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

export function formatStartEndHoursString(
  startTime: string,
  endTime: string,
  timeZone: string,
) {
  const hourFormat = 'h:mm aa';
  const hourFormatWithTimeZone = hourFormat + ' zzz';
  const start = formatInTimeZone(new Date(startTime), timeZone, hourFormat);
  const end = formatInTimeZone(
    new Date(endTime),
    timeZone,
    hourFormatWithTimeZone,
  );
  return `${start} - ${end}`;
}

export function formatDateString(
  date: string,
  timeZone: string,
  dateTimeFormat?: string,
) {
  const dateFormat = 'MMM dd, yyyy';
  return formatInTimeZone(
    new Date(date),
    timeZone,
    dateTimeFormat ?? dateFormat,
  );
}

export function formatHoursMinutes(times: number) {
  const seconds = times * 3600;
  const duration = intervalToDuration({ start: 0, end: seconds * 1000 });
  const { hours, minutes } = duration;
  if (hours === 0) {
    return `${minutes} mins`;
  }
  if (minutes === 0) {
    return `${hours} hours`;
  }
  return `${hours} hours ${minutes} mins`;
}
