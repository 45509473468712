import { useState } from 'react';
import FormAbbreviation from 'styleguide/src/components/FormAbbreviation';
import { FormStatus, FormInstance } from 'common/types/Form';
import { FORM_STATUS_MAP } from 'utils/mappings/form';
import {
  JobAttachmentForm,
  JobAttachmentFormContainer,
  JobAttachmentFormLabel,
  JobAttachmentForms,
  JobAttachmentFormTitle,
  JobDetailSection,
  JobItemHead,
} from '../JobDetail.styles';
import JobWorkFormModal from './JobWorkForms/JobFormModal';

type JobWorkFormProp = {
  formInstances: FormInstance[];
  jobId: number;
  jobTitle: string;
};

const JobWorkForm = ({ formInstances, jobId, jobTitle }: JobWorkFormProp) => {
  const [openFormModal, setOpenFormModal] = useState(false);
  const [selectedForm, setSelectedForm] = useState<FormInstance | undefined>(
    undefined,
  );

  if (!formInstances || formInstances?.length === 0) return null;

  const onClick = (form: FormInstance) => {
    setOpenFormModal(true);
    setSelectedForm(form);
  };

  const onCloseModal = () => {
    setOpenFormModal(false);
    setSelectedForm(undefined);
  };

  return (
    <JobDetailSection>
      <JobItemHead variant="ctaSmall">Forms</JobItemHead>
      <JobAttachmentForms>
        {formInstances?.map((instance, i) => {
          return (
            <JobAttachmentForm key={i} onClick={() => onClick(instance)}>
              <FormAbbreviation
                value={instance?.form?.abbreviation}
                fill={instance?.status === FormStatus.SUBMITTED}
              />
              <JobAttachmentFormContainer>
                <JobAttachmentFormTitle variant="bodyStandard">
                  {instance?.form?.title}
                </JobAttachmentFormTitle>
                <JobAttachmentFormLabel>
                  {FORM_STATUS_MAP[instance.status]}
                </JobAttachmentFormLabel>
              </JobAttachmentFormContainer>
            </JobAttachmentForm>
          );
        })}
      </JobAttachmentForms>
      {openFormModal && (
        <JobWorkFormModal
          formInstance={selectedForm}
          jobId={jobId}
          jobTitle={jobTitle}
          open={openFormModal}
          onClose={onCloseModal}
        />
      )}
    </JobDetailSection>
  );
};

export default JobWorkForm;
