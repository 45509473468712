import type { ContextMenuType } from 'styleguide/src/components/ContextMenu';
import CircleAlertIcon from 'styleguide/src/components/icons/CircleAlertIcon';
import StatusLabel from 'styleguide/src/components/StatusLabel';
import ContextMenu from 'styleguide/src/components/ContextMenu';
import { WorkOrder } from 'common/types/WorkOrder';
import { WORK_ORDER_STATUS_MAP } from 'utils/mappings/workOrderStatus';
import {
  JobNotifyWithActionContainer,
  JobNotifyContainer,
  JobNotify,
  JobActionContainer,
} from '../JobDetail.styles';
import Dropdown from 'styleguide/src/components/Dropdown';
import { useCallback, useEffect, useState } from 'react';
import { WorkOrderStatus } from 'common/types/WorkOrderStatus';
import { useUpdateWorkOrderMutation } from 'services/endpoints/workOrder';
import { format } from 'date-fns';

type JobNotifyWithActionProps = {
  workOrder: WorkOrder;
  items: ContextMenuType[];
  refetch?: (workOrder: WorkOrder) => void;
};

const JobNotifyWithAction = ({
  workOrder,
  items,
  refetch,
}: JobNotifyWithActionProps) => {
  const [workOrderStatus, setWorkOrderStatus] = useState(
    workOrder?.status || '',
  );

  const [updateWorkOrder] = useUpdateWorkOrderMutation();

  useEffect(() => {
    if (workOrder?.status) {
      setWorkOrderStatus(workOrder.status);
    }
  }, [workOrder?.status]);

  const onChangeStatus = useCallback(
    async (value: WorkOrderStatus) => {
      if (workOrder?.id) {
        setWorkOrderStatus(value);
        await updateWorkOrder({
          id: workOrder?.id,
          data: { status: value },
        });
        refetch && refetch({ ...workOrder, status: value });
      }
    },
    [refetch, updateWorkOrder, workOrder],
  );

  if (!workOrder) return <></>;

  const updateAt = format(
    new Date(workOrder.updatedAt),
    'MMMM dd, yyyy - h:mmaaa',
  );
  const workOrderStatusLists = Object.keys(WORK_ORDER_STATUS_MAP).reduce(
    (status, key: WorkOrderStatus) => {
      status.push({
        label: (
          <StatusLabel
            color={WORK_ORDER_STATUS_MAP[key].color}
            text={WORK_ORDER_STATUS_MAP[key].label}
          />
        ),
        isDisabled: key === WorkOrderStatus.IN_PROGRESS,
        value: key,
      });
      return status;
    },
    [],
  );

  return (
    <JobNotifyWithActionContainer>
      <JobNotifyContainer>
        <CircleAlertIcon color="#1C1B1F" />
        <JobNotify variant="heading5">
          This job was last updated on {updateAt}
        </JobNotify>
      </JobNotifyContainer>
      <JobActionContainer>
        <Dropdown
          options={workOrderStatusLists}
          onChange={onChangeStatus}
          value={workOrderStatus}
        />
        <ContextMenu align="end" items={items} data-testid="job-context-menu" />
      </JobActionContainer>
    </JobNotifyWithActionContainer>
  );
};

export default JobNotifyWithAction;
