import { WorkOrder } from 'common/types/WorkOrder';
import { IdContainer, TitleContainer } from './TitleCell.styles';

function TitleCell({ id, title }: WorkOrder) {
  return (
    <div>
      {id && <IdContainer>JOB-{id}</IdContainer>}
      <TitleContainer>{title}</TitleContainer>
    </div>
  );
}

export default TitleCell;
