import Tag from 'styleguide/src/components/Tag';
import type { SkillRequest } from 'common/types/SkillTag';
import type { Service } from 'common/types/Service';
import {
  JobDetailSection,
  JobItemHead,
  JobItem,
  TagWrapper,
} from '../JobDetail.styles';

type JobTagDetailProps = {
  skillRequests?: SkillRequest[];
  services?: Service[];
};

const JobTagDetail = ({ skillRequests, services }: JobTagDetailProps) => {
  return (
    <JobDetailSection>
      <JobItemHead variant="ctaSmall">Skills Required</JobItemHead>
      <JobItem variant="bodyStandard">
        <TagWrapper>
          {skillRequests?.map((s, i) => (
            <Tag
              variant="secondary"
              text={`${s.categoryTitle} - ${s.itemTitle} - ${s.title}`}
              key={`${s.id}_${i}`}
            />
          ))}
        </TagWrapper>
      </JobItem>
      <JobItemHead variant="ctaSmall">Services Notified</JobItemHead>
      <JobItem variant="bodyStandard">
        <TagWrapper>
          {services.map((s) => (
            <Tag text={s.title} key={s.title} icon={s.icon} />
          ))}
        </TagWrapper>
      </JobItem>
    </JobDetailSection>
  );
};

export default JobTagDetail;
