import Typography from 'styleguide/src/components/Typography';
import {
  JobDetailSection,
  JobItemHead,
  JobTaskLists,
  JobTaskListContainer,
  JobTaskListRadio,
} from '../JobDetail.styles';

type JobTaskListProp = {
  tasks: string[] | undefined;
};

const JobTaskList = ({ tasks }: JobTaskListProp) => {
  if (!tasks || tasks?.length === 0) return <></>;
  return (
    <JobDetailSection>
      <JobItemHead variant="ctaSmall">Task List</JobItemHead>
      <JobTaskLists>
        {tasks?.map((task, i) => (
          <JobTaskListContainer key={i}>
            <JobTaskListRadio type="radio" disabled />
            <Typography variant="bodyStandard">{task}</Typography>
          </JobTaskListContainer>
        ))}
      </JobTaskLists>
    </JobDetailSection>
  );
};

export default JobTaskList;
