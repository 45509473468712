import styled from 'styled-components';

export const FormsCellStyled = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(#fff, transparent);
  }
`;
