import type { Attachment } from 'styleguide/src/components/Attachments';
import { AttachmentsList } from 'styleguide/src/components/Attachments/Attachments.styles';
import FileEmptyIcon from 'styleguide/src/components/icons/FileEmptyIcon';
import ImageAttachment from 'styleguide/src/components/ImageAttachment';
import {
  JobDetailSection,
  JobItemHead,
  AttachmentItem,
  AttachmentFile,
  AttachmentFileExtension,
} from '../JobDetail.styles';

type JobAttachmentsDetailProp = {
  attachments: Attachment[];
};

const JobAttachmentsDetail = ({ attachments }: JobAttachmentsDetailProp) => {
  if (!attachments || attachments?.length === 0) return <></>;
  return (
    <JobDetailSection>
      <JobItemHead variant="ctaSmall">Attachments</JobItemHead>
      <AttachmentsList>
        {attachments.map((attachment, i) => {
          const file = {
            url: attachment?.filePath,
          };
          const ext =
            (attachment?.filePath &&
              attachment?.filePath
                .substring(attachment?.filePath.lastIndexOf('/') + 1)
                .split('.', 2)
                .pop()
                .split('?', 1)
                .pop()) ??
            '';

          return attachment?.type === 'PHOTO' ? (
            <ImageAttachment key={i} file={file} />
          ) : (
            <AttachmentItem>
              <AttachmentFile>
                <FileEmptyIcon width={80} height={90} fillOpacity={0.1} />
                <AttachmentFileExtension>{ext}</AttachmentFileExtension>
              </AttachmentFile>
            </AttachmentItem>
          );
        })}
      </AttachmentsList>
    </JobDetailSection>
  );
};

export default JobAttachmentsDetail;
