import { WorkOrder } from 'common/types/WorkOrder';
import JobAvailableWorkingTimeSlot from './JobAvailableWorkingTimeSlot';
import { JobDetailSection, JobItemHead, JobItem } from '../JobDetail.styles';
import {
  formatDateString,
  formatHoursMinutes,
} from 'utils/helpers/formatDateTimes';

type JobWorkingDetailProps = {
  workOrder: WorkOrder;
};

const JobWorkingDetail = ({ workOrder }: JobWorkingDetailProps) => {
  const {
    estimatedWorkHours,
    availableTimeSlots,
    acceptedTimeSlot,
    market,
    startTime,
    endTime,
    workHours,
    expectedEndTime,
    pausedAt,
  } = workOrder;
  const { timeZone } = market;
  if (!availableTimeSlots || availableTimeSlots?.length === 0) return <></>;

  return (
    <JobDetailSection>
      {startTime && (
        <>
          <JobItemHead variant="ctaSmall">Start time</JobItemHead>
          <JobItem variant="bodyStandard">
            {formatDateString(startTime, timeZone, 'h:mm aa, MMM d, yyyy')}
          </JobItem>
        </>
      )}
      {expectedEndTime && (
        <>
          <JobItemHead variant="ctaSmall">Expected end time</JobItemHead>
          <JobItem
            variant="bodyStandard"
            color={pausedAt ? 'alertRed' : undefined}
          >
            {pausedAt && (
              <>
                Timer Paused at{' '}
                {formatDateString(pausedAt, timeZone, 'h:mm aa, MMM d, yyyy')}-
                Last expected end time was{' '}
              </>
            )}
            {formatDateString(
              expectedEndTime,
              timeZone,
              'h:mm aa, MMM d, yyyy',
            )}
          </JobItem>
        </>
      )}
      {endTime && (
        <>
          <JobItemHead variant="ctaSmall">End time</JobItemHead>
          <JobItem variant="bodyStandard">
            {formatDateString(endTime, timeZone, 'h:mm aa, MMM d, yyyy')}
          </JobItem>
        </>
      )}
      {Boolean(workHours) && (
        <>
          <JobItemHead variant="ctaSmall">Total work hours</JobItemHead>
          <JobItem variant="bodyStandard">
            {formatHoursMinutes(workHours)}
          </JobItem>
        </>
      )}
      <JobItemHead variant="ctaSmall">Estimated work hours</JobItemHead>
      <JobItem variant="bodyStandard">{estimatedWorkHours} hours</JobItem>
      <JobItemHead variant="ctaSmall">Available working time slots</JobItemHead>
      <JobAvailableWorkingTimeSlot
        timeZone={timeZone}
        availableTimeSlots={availableTimeSlots}
        acceptedTimeSlot={acceptedTimeSlot}
      />
    </JobDetailSection>
  );
};

export default JobWorkingDetail;
