import styled from 'styled-components';
import { BodyStandard } from 'styleguide/src/components/Typography/Typography.styles';

export const IdContainer = styled.div`
  color: #5d5d5d;
  font-size: 10px;
  line-height: 13px;
  white-space: nowrap;
`;

export const TitleContainer = styled.div`
  margin: 6px 0;
  ${BodyStandard};
  color: ${(props) => props.theme.colors.black};
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
`;
