import type { WorkOrderStatusType } from 'common/types/WorkOrder';
import theme from 'styleguide/src/themes/theme';

export const WORK_ORDER_STATUS_MAP: WorkOrderStatusType = {
  DRAFT: { color: theme.colors.gray400, label: 'Draft' },
  UNCLAIMED: {
    color: theme.colors.wrenoYellowPrimary,
    label: 'Unclaimed',
  },
  UNACCEPTED: {
    color: theme.colors.wrenoYellowPrimary,
    label: 'Unaccepted',
  },
  ACCEPTED: { color: '#FFE500', label: 'Accepted' },
  IN_PROGRESS: { color: '#FFDDF6', label: 'In Progress' },
  SUBMITTED: { color: '#C9E5FF', label: 'Submitted' },
  IN_REVIEW: { color: '#6177F6', label: 'In Review' },
  APPROVED: { color: '#76F2A8', label: 'Approved' },
  REWORK: { color: '#D74149', label: 'Rework' },
  TRIP_FEE_ONLY: { color: '#EBEBFF', label: 'Trip Fee Only' },
  PAID: { color: '#DDF1CA', label: 'Paid' },
  REJECTED: { color: '#E59CFF', label: 'Rejected' },
  CANT_SERVICE: { color: '#570606', label: "Can't Service" },
};
