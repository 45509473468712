import type { WorkOrder, WorkOrderWithIdRequest } from 'common/types/WorkOrder';
import { WORK_ORDER_STATUS_MAP } from 'utils/mappings/workOrderStatus';
import {
  StatusCellTitleAndIndicator,
  StatusCellIndicator,
  StatusCellTitle,
} from './StatusCell.styles';
import ContextButtons from '../ContextButtons/ContextButtons';

interface StatusCellProps {
  workOrder: WorkOrder | WorkOrderWithIdRequest;
  onShowDetails?: (workOrder: WorkOrder | WorkOrderWithIdRequest) => void;
}

const StatusCell = ({ onShowDetails, workOrder }: StatusCellProps) => {
  const details = WORK_ORDER_STATUS_MAP[workOrder.status];
  return (
    <>
      <ContextButtons onShowDetails={onShowDetails} workOrder={workOrder} />
      <StatusCellTitleAndIndicator>
        <StatusCellIndicator color={details?.color} />
        <StatusCellTitle>{details?.label || 'N/A'}</StatusCellTitle>
      </StatusCellTitleAndIndicator>
    </>
  );
};

export default StatusCell;
