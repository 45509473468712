import type { SVGAttributes } from 'react';

const DuplicateIcon = (props: SVGAttributes<SVGElement>) => (
  <svg
    width={24}
    height={25}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 25"
    {...props}
  >
    <mask
      id="a"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={24}
      height={25}
    >
      <path fill="currentColor" d="M0 .5h24v24H0z" />
    </mask>
    <g mask="url(#a)">
      <path
        d="M5.412 24c-.377 0-.706-.142-.988-.427A1.373 1.373 0 0 1 4 22.575V8.254h1.412v14.321h11.153V24H5.412Zm2.823-2.85c-.376 0-.706-.142-.988-.427a1.373 1.373 0 0 1-.423-.998v-13.3c0-.38.14-.712.423-.997C7.53 5.143 7.86 5 8.235 5h10.353c.377 0 .706.143.988.428.283.285.424.617.424.997v13.3c0 .38-.141.712-.424.997a1.349 1.349 0 0 1-.988.428H8.235Zm0-1.425h10.353v-13.3H8.235v13.3Zm0 0v-13.3 13.3Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default DuplicateIcon;
