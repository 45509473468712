import {
  StatusCellDetailsButton,
  StatusCellEditButton,
} from '../StatusCell/StatusCell.styles';
import EditIcon from 'styleguide/src/components/icons/EditIcon';
import { WorkOrder, WorkOrderWithIdRequest } from 'common/types/WorkOrder';

interface ContextButtonProps {
  onShowDetails?: (workOrder: WorkOrder | WorkOrderWithIdRequest) => void;
  workOrder: WorkOrder | WorkOrderWithIdRequest;
}

function ContextButtons({ workOrder, onShowDetails }: ContextButtonProps) {
  const { type, id } = workOrder;
  const detailsUrl = `/jobs-and-projects/${id}`;
  const editUrl = `/jobs-and-projects/${type.toLowerCase()}s/${id}/edit`;

  return (
    <>
      <StatusCellDetailsButton
        variant="secondary"
        href={onShowDetails ? undefined : detailsUrl}
        onClick={(e) => {
          e.stopPropagation();
          if (onShowDetails) onShowDetails(workOrder);
        }}
      >
        Details
      </StatusCellDetailsButton>
      <StatusCellEditButton
        variant="secondary"
        href={editUrl}
        onClick={(e) => e.stopPropagation()}
      >
        <EditIcon width="18px" height="18px" />
      </StatusCellEditButton>
    </>
  );
}

export default ContextButtons;
